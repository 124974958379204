<template>
    <v-container
      class="empty-state"
      fill-height
      align-center
      justify-center
      text-center
    >
      <v-row>
        <v-col>
          <v-icon large :color="iconColor">{{ icon }}</v-icon>
          <h2 class="mt-3">{{ title }}</h2>
          <p class="mt-2">{{ description }}</p>
          <v-btn v-if="actionText" @click="action" color="primary">{{ actionText }}</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  export default {
    name: 'VEmptyState',
    props: {
      icon: {
        type: String,
        default: 'mdi-alert-circle-outline'
      },
      iconColor: {
        type: String,
        default: 'primary'
      },
      title: {
        type: String,
        default: 'No items found'
      },
      description: {
        type: String,
        default: 'Try adjusting your search or filter to find what you are looking for.'
      },
      actionText: {
        type: String,
        default: ''
      },
      action: {
        type: Function,
        default: () => {}
      }
    }
  }
  </script>
  
  <style scoped>
  .empty-state {
    padding: 2rem;
  }
  </style>
  