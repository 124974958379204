<template>
  <v-container class="my-6">
    <v-data-iterator
      :items="displayedReports"
      :items-per-page.sync="itemsPerPage"
      :page.sync="page"
      no-data-text=""
      hide-default-footer
    >
      <template v-slot:header>
        <v-progress-linear v-if="loading" indeterminate color="primary" class="my-6"></v-progress-linear>
        <v-card v-if="!loading" flat>
          <v-toolbar class="secondary" rounded>
            <v-toolbar-title class="white--text">Reported Issues</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card>
            <v-chip-group v-model="reportType" active-class="primary" column @change="changeReportType" mandatory>
              <v-icon class="mr-2">mdi-filter-variant</v-icon> 
              <v-chip small outlined filter :value="'cases'">Case Reports</v-chip>
              <v-chip small outlined filter :value="'general'">General Reports</v-chip>
            </v-chip-group>
            <v-spacer></v-spacer>
            <v-chip-group v-if="reportType === 'cases'"  v-model="currentFilter" active-class="primary" column @change="filterReports" mandatory>
              <v-chip small outlined filter :value="'all'">All</v-chip>
              <v-chip small outlined filter :value="true">Active</v-chip>
              <v-chip small outlined filter :value="false">Solved</v-chip>
            </v-chip-group>
          </v-card>
        </v-card>
      </template>

      <template v-slot:default="{ items }">
        <v-row v-if="items.length > 0">
          <v-col v-for="(report, index) in items" :key="index" class="mt-3" cols="12" md="6">
            <v-card :class="['custom-card', darkTheme ? 'dark-card' : 'light-card']">
              <v-card-actions :class="['custom-card-actions', darkTheme ? 'dark-card' : 'light-card']">
                <div class="responsive-actions">
                  <v-btn v-if="report.userReport[0].reportIsActive" color="primary" small @click="solvedReport(report.id)">
                    <v-icon left>mdi-checkbox-marked-circle</v-icon>
                    Mark as solved
                  </v-btn>
                  <div class="my-3">
                    <v-icon :color="report.userReport[0].reportIsActive ? 'red' : 'green'" right>
                      mdi-checkbox-marked-circle
                    </v-icon>
                    <span>{{ report.userReport[0].reportIsActive ? 'Active' : 'Solved' }}</span>
                  </div>
                </div>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mr-2 white--text"
                      fab
                      color="primary"
                      elevation="7"
                      raised
                      v-bind="attrs"
                      v-on="on"
                      @click="openDeleteDialog(report.id)"
                      small
                    >
                      <v-icon dark>mdi-delete-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete Report</span>
                </v-tooltip>
              </v-card-actions>

              <v-card-subtitle v-if="report.references">
                <h3>{{ report.references[0].refno }} <br>{{ report.references[0].short }}</h3>
                {{ report.references[0].title }}
              </v-card-subtitle>
              <v-divider secondary></v-divider>
              <v-card-text class="mx-3">
                <h2><strong>{{ report.userReport[0].reportTopic }}</strong></h2>
                <p><br><strong>Report Description</strong><br>{{ report.userReport[0].reportDetails }}</p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-card v-else>
          <v-alert dense text type="info">
            No submitted reports.
          </v-alert>
        </v-card>
      </template>

      <template v-slot:no-results>
        <v-empty-state
          icon="mdi-alert-circle-outline"
          title="No reports found"
          description="There are no reports to show."
        />
      </template>

      <template v-slot:no-data>
        <v-empty-state
          icon="mdi-alert-circle-outline"
          title="No reports found"
          description="There are no reports to show."
        />
      </template>

      <template v-slot:footer>
        <v-row class="mt-2" align="center" justify="center">
          <v-col cols="12" md="8">
            <v-row align="center" justify="center">
              <v-pagination v-model="page" :length="numberOfPages" :total-visible="5" circle color="primary"></v-pagination>
            </v-row>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>

    <!-- Delete Confirmation Modal -->
    <v-dialog v-model="deleteDialog" persistent max-width="500px">
      <v-card outlined>
        <v-card-title class="headline">Confirm Deletion</v-card-title>
        <v-card-text>Are you sure you want to delete this report?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" small outlined @click="deleteDialog = false">Cancel</v-btn>
          <v-btn color="primary" small @click="confirmDelete">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Snackbar for Notifications -->
    <v-snackbar v-model="snackbar" :color="snackbarType" :timeout="snackbarTimeout" text>
      {{ snackbarMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text small v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { casesCollection, db, usersCollection } from "../../firebaseDb";
import { mapGetters } from "vuex";
import VEmptyState from '@/components/VEmptyState.vue';

export default {
  components: {
    VEmptyState,
  },
  data() {
    return {
      userReports: [],
      snackbar: false,
      snackbarMessage: "",
      snackbarTitle: "",
      snackbarType: "success",
      snackbarTimeout: 10000,
      filteredReports: [],
      currentFilter: 'all',
      page: 1,
      itemsPerPage: 6,
      loading: false,
      deleteDialog: false,
      reportToDelete: null,
      generalReports: [],
      reportType: 'cases', 
    };
  },
  async created() {
    this.loading = true;
    try {
      const reportsCollection = db.collection("reports");
      const querySnapshot = await reportsCollection.get();
      querySnapshot.forEach(async (doc) => {
        const reportData = { id: doc.id, ...doc.data() };
        // Check if caseRef is empty and reportTopic is "General Report"
        const userReport = reportData.userReport[0];
        if (userReport.caseRef === '' && userReport.reportTopic === 'General Report') {
          // Push the report into generalReports array but continue processing the rest
          this.generalReports.push(reportData);
        } else {
          // Continue with existing process for reports that are not in "General Report"
          const caseDoc = await casesCollection.doc(userReport.caseRef).get();
          if (caseDoc.exists) {
            reportData.references = caseDoc.data().references;
          }
          this.userReports.push(reportData);
        }
      });
      this.filteredReports = this.userReports;
    } catch (error) {
      console.error("Error retrieving reports: ", error);
    } finally {
      this.loading = false;
    }
  },
  computed: {
    ...mapGetters(['darkTheme']),
    numberOfPages() {
      return Math.ceil(this.filteredReports.length / this.itemsPerPage);
    },
    displayedReports() {
      // Show general reports if reportType is 'general', otherwise show filteredReports
      return this.reportType === 'general' ? this.generalReports : this.filteredReports;
    },
  },
  watch: {
    filteredReports(newReports) {
      const uniqueCaseIds = new Set();
      newReports.forEach(report => {
        if (!report.userReport[0].caseRef.includes('drafts/')) {
          uniqueCaseIds.add(report.userReport[0].caseRef);
        }
      });
      this.caseIds = Array.from(uniqueCaseIds);
    },
  },
  methods: {
    openDeleteDialog(reportId) {
      this.reportToDelete = reportId;
      this.deleteDialog = true;
    },
    async confirmDelete() {
      if (this.reportToDelete) {
        try {
          const docRef = db.collection("reports").doc(this.reportToDelete);
          
          // Delete the document from Firestore
          await docRef.delete();
          
          const reportsReference = "reports/" + this.reportToDelete;

          // Find the report in userReports or generalReports
          const indexInUserReports = this.userReports.findIndex(report => report.id === this.reportToDelete);
          const indexInGeneralReports = this.generalReports.findIndex(report => report.id === this.reportToDelete);
          
          if (indexInUserReports !== -1) {
            // Remove from userReports if found
            this.userReports = this.userReports.filter(report => report.id !== this.reportToDelete);
            this.filteredReports = this.filteredReports.filter(report => report.id !== this.reportToDelete); // For UI update
          } else if (indexInGeneralReports !== -1) {
            // Remove from generalReports if found
            this.generalReports = this.generalReports.filter(report => report.id !== this.reportToDelete);
          }

          // Optionally, remove report reference from users' reportsArray
          const usersSnapshot = await usersCollection.where("reportsArray", "array-contains", reportsReference).get();
          usersSnapshot.forEach(async (userDoc) => {
            const userData = userDoc.data();
            const updatedReports = userData.reportsArray.filter(reportRef => reportRef !== reportsReference);
            await usersCollection.doc(userDoc.id).update({ reportsArray: updatedReports });
          });

          // Show snackbar notification
          this.snackbarMessage = "Report deleted successfully!";
          this.snackbarTitle = "Success!";
          this.snackbarType = "info";
          this.snackbar = true;
          setTimeout(() => { this.snackbar = false; }, this.snackbarTimeout);

        } catch (error) {
          console.error("Error deleting document: ", error);
        } finally {
          this.deleteDialog = false;
          this.reportToDelete = null;
        }
      }
    },
    filterReports(active) {
      this.currentFilter = active; 
      if (active === 'all') {
        this.filteredReports = this.userReports;
      } else {
        this.filteredReports = this.userReports.filter(report => {
          return active === report.userReport[0].reportIsActive;
        });
      }
    },
    changeReportType(type) {
      if (type === 'cases') {
        this.reportType = 'cases';
      } else {
        this.reportType = 'general';
      }
    },
    async solvedReport(reportId) {
      try {
        const docRef = db.collection("reports").doc(reportId);
        const docSnapshot = await docRef.get();
        const reportData = docSnapshot.data();
        const currentIsActive = reportData.userReport[0].reportIsActive;
        reportData.userReport[0].reportIsActive = !currentIsActive;
        
        // Update the report in Firestore
        await docRef.update({ userReport: reportData.userReport });
        
        // Find the report in userReports or generalReports and update it
        const indexInUserReports = this.userReports.findIndex(report => report.id === reportId);
        const indexInGeneralReports = this.generalReports.findIndex(report => report.id === reportId);
        
        if (indexInUserReports !== -1) {
          // Update in userReports if found
          this.$set(this.userReports[indexInUserReports].userReport[0], 'reportIsActive', !currentIsActive);
        } else if (indexInGeneralReports !== -1) {
          // Update in generalReports if found
          this.$set(this.generalReports[indexInGeneralReports].userReport[0], 'reportIsActive', !currentIsActive);
        }

        // Show snackbar notification
        const message = currentIsActive ? "You have marked this report as solved!" : "You have marked this report as active!";
        this.snackbarMessage = message;
        this.snackbarTitle = currentIsActive ? "Success!" : "Info!";
        this.snackbarType = currentIsActive ? "success" : "info";
        this.snackbar = true;
        setTimeout(() => { this.snackbar = false; }, this.snackbarTimeout);
      } catch (error) {
        console.error("Error toggling report status: ", error);
      }
    }
  },
}
</script>

<style scoped>
.custom-card-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dark-card {
  background-color: #424242;
  color: white;
}
.light-card {
  background-color: white;
  color: black;
}
.no-cases-found {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
}
</style>
