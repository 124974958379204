<template>
  <v-container fluid>
    <v-card style="min-height: 100%;" elevation="0" max-width="1300px" class="mx-auto">
      <v-card-title class="text-center justify-center py-6">
        <h1 class="text-h5"><strong>
          Admin Dashboard</strong> 
        </h1>
      </v-card-title>
  
      <v-tabs
        v-model="activeTab"
        centered
        flat
        show-arrows
        prev-icon="mdi-chevron-left"
        next-icon="mdi-chevron-right"
        active-class="rounded secondary white--text"
        :prev-class="darkTheme ? 'dark-arrow' : ''"
        :next-class="darkTheme ? 'dark-arrow' : ''"
      >
        <v-tab v-for="(tab, index) in tabs" :key="index">
          {{ tab }}
        </v-tab>
      </v-tabs>
  
      <v-tabs-items v-model="activeTab">
        <v-tab-item>
            <DashboardReports />
        </v-tab-item>
        
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import DashboardReports from "./DashboardReports";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      activeTab: 0,
      tabs: ["Manage Reports"],
    };
  },
  components: {
    DashboardReports,
  },
  computed: {
    ...mapGetters(['darkTheme']),
  },
}
</script>